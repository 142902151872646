import html from "nanohtml";
import Component from "nanocomponent";
import { FeedbackItem, FeedbackDiscussionItem } from "../model";

import { View, ViewT } from "research-go-shared/lib/choo-modules/view";
import { close, arrowLeft } from "research-go-shared/lib/icons";
import {
  buildCard,
  personCardHeader
} from "research-go-shared/lib/components/user-card";
import { timeAgo } from "research-go-shared/lib/util/dates";
import { Futurish } from "useful-webapp-monads/dist/futurish";
import { bouncyLoader } from "research-go-shared/lib/components/loader";
import { defaultErrorMsg } from "research-go-shared/lib/components/error-msg";

const getNow = () => new Date();

export const renderSingleFeedback: (
  x: FeedbackItem,
  onClick,
  resolveFeedback
) => HTMLElement = (
  {
    displayName,
    photoUrl,
    feedback,
    date,
    feedbackId,
    addressedOn,
    addressedBy,
    discussionCount,
    emailAddress,
    businessPhones
  },
  onClick,
  resolveFeedback
) => {
  const markAddressedButton = addressedOn
    ? html`
        <div class="addressed">
          Addressed by ${addressedBy.displayName}
          ${timeAgo(getNow, addressedOn)}
        </div>
      `
    : html`
        <button
          class="mcui-btn primary"
          onclick=${() => resolveFeedback(feedbackId)}
        >
          Mark as addressed
        </button>
      `;
  const feedbackSpecificStuff = html`
    <div class="feedback-content">${feedback}</div>
    <div class="timestamp">
      ${timeAgo(() => new Date(), date.toISOString())}
    </div>
    <div class="actions">
      ${markAddressedButton}
      <button class="mcui-btn minimalist" onclick=${() => onClick(feedbackId)}>
        See discussion (${discussionCount})
      </button>
    </div>
  `;
  return html`
    <li class="feedback-item">
      ${buildCard(
        personCardHeader(displayName, photoUrl, emailAddress, businessPhones)
      )(feedbackSpecificStuff)}
    </li>
  `;
};

export const renderFeedbackList: ViewT<{
  viewNotes: (id: string) => void;
  resolveFeedback: (id: string) => void;
  feedback: FeedbackItem[];
}> = View(({ feedback, resolveFeedback, viewNotes }) => {
  const listItems = feedback.map(x =>
    renderSingleFeedback(x, viewNotes, resolveFeedback)
  );

  return html`
    <ul class="feedback-list user-cards">
      ${listItems}
    </ul>
  `;
});

const singleNote = (n: FeedbackDiscussionItem) =>
  html`
    <li>
      <div class="note">${n.note}</div>
      <img src=${n.user.photoUrl} />
      <div class="name">${n.user.displayName}</div>
      <div class="timestamp">${timeAgo(() => new Date(), n.dateCreated)}</div>
    </li>
  `;

export const renderDiscussion: ViewT<{
  selectedFeedback: number;
  selectedNotes: Futurish<FeedbackDiscussionItem[], Error>;
  closeDiscussion: () => unknown;
  onNoteSubmit: (feedbackId: number, note: string) => void;
  componentCache: any;
}> = View(
  ({
    selectedFeedback,
    selectedNotes,
    closeDiscussion,
    onNoteSubmit,
    componentCache
  }) => {
    const onSubmit = x =>
      onNoteSubmit(selectedFeedback, x.target.usernote.value);

    const addToDiscussion = componentCache(
      TextArea,
      `add-discussion-note-${selectedFeedback}`
    );
    return selectedNotes
      .map(
        x => html`
          <div class="discussion">
            <header>
              <h1>Discussion</h1>
              <button class="close" onclick=${closeDiscussion}>
                ${close()}
              </button>
            </header>
            <ol>
              ${x.map(singleNote)}
            </ol>
            <form
              onsubmit=${e => (
                e.preventDefault(), addToDiscussion.reset(), onSubmit(e)
              )}
            >
              ${addToDiscussion.render()}
              <button type="submit" class="mcui-btn primary">Submit</button>
            </form>
          </div>
        `
      )
      .eval<HTMLElement>(
        x => x,
        () => bouncyLoader(),
        () => defaultErrorMsg("Couldn't load that discussion."),
        () => html``
      );
  }
);

class TextArea extends Component {
  private value: string;
  constructor(id, state, emit) {
    super(id);
    this.value = "";
  }

  public reset() {
    console.log("RESETTING");
    this.value = "";
    console.log("RESETTING", this.value);
  }

  public update() {
    return true;
  }

  public createElement(data) {
    //Tell prettier to leave this dom node alone, since white space is significant
    //prettier-ignore
    return html`
      <textarea
        id="add-discussion-note"
        name="usernote"
        oninput=${e => (this.value = e.target.value)}
      >${this.value}</textarea
      >
    `;
  }
}

const header: ViewT<{
  showAddressed: boolean;
  toggleShowAll: () => void;
}> = View(({ showAddressed, toggleShowAll }) => {
  const changeFilterText = showAddressed ? "Show only unaddressed" : "Show all";
  return html`
    <header class="user-feedback">
      <h1><a href="/research-go/admin">${arrowLeft()}</a>User Feedback</h1>
      <button onclick=${toggleShowAll} class="mcui-btn minimalist">
        ${changeFilterText}
      </button>
    </header>
  `;
});

export const reviewFeedbackView = header
  .concat(renderFeedbackList)
  .concat(renderDiscussion);
