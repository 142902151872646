import { lens, set as rSet } from "ramda";
import {
  clipboard,
  gear,
  readMe,
  feedback,
  findElsewhere,
  thumbsUp,
  briefCase,
  documents,
} from "research-go-shared/lib/icons";
import {
  AppModule,
  ViewState,
} from "research-go-shared/lib/choo-modules/module";
import { homeEvents } from "../home/events";
import { bouncyLoader } from "research-go-shared/lib/components/loader";
import { defaultErrorMsg } from "research-go-shared/lib/components/error-msg";
import { Waiting, Complete, Err } from "useful-webapp-monads/dist/futurish";
import { announcementFunctions } from "../announcements/http-announcements";
import { Announcement } from "../announcements/model";
import { events as sharedEvents } from "research-go-shared/lib/events";
import { announcementsView } from "../announcements/announcement-list";
import { AppUrls } from "../appUrls/model";
import { UserContext } from "../user/model";
import { HomeState, AnnouncementFilter, App } from "../home/model";
import { UndoOption } from "research-go-shared/lib/undos/module";
import html from "nanohtml";

export const homeModule: (
  fn: () => UserContext,
  appUrls: AppUrls,
  researchGoFetch: any,
  isAdmin: boolean,
  isAIReviewer: boolean,
  isEpicenter: boolean
) => AppModule<HomeState> = (
  getUserContext,
  appUrls,
  researchGoFetch,
  isAdmin,
  isAIReviewer
) => ({
  views: {
    "": function (input: ViewState<HomeState>) {
      const { apps, announcements, trouble } = input.getState();
      const announcementCount = announcements.list.eval(
        (a) => a.length,
        () => 0,
        () => 0
      );

      const announcementPlural =
        announcementCount === 1 ? "announcement" : "announcements";
      const announcementMsg = announcementCount
        ? html`
            <div>
              You have ${announcementCount}
              <a href="home/announcements">${announcementPlural}</a> to read.
            </div>
          `
        : "";
      const lowerFirst = (s: string) => s[0].toLowerCase() + s.slice(1);
      return html`
        <div id="home-view" class="bordered-icons">
          <header class="user">
            <h1>Hello, ${getUserContext().givenName}!</h1>
            <div>Welcome to Research Go! ${announcementMsg}</div>
            <img src=${getUserContext().photo} />
          </header>
          <div>
            <section>
              <ul class="apps">
                ${apps.map((x) =>
                  toAppLi(
                    x,
                    trouble[lowerFirst(x.displayName)]
                      ? trouble[lowerFirst(x.displayName)]
                      : ""
                  )
                )}
              </ul>
            </section>
          </div>
        </div>
      `;
    },
    "/announcements": function (input: ViewState<HomeState>) {
      function wrapWithHeader(el: HTMLElement) {
        const showingUnread =
          input.getState().announcements.filters === "unread";
        const filterButtonTxt = showingUnread ? "Show all" : "Show unread";
        const eventToEmit = showingUnread
          ? homeEvents.announcements.showAll
          : homeEvents.announcements.showUnread;
        return html`
          <div id="research-go-announcements">
            <header>
              <h1>Announcements</h1>
              <button
                class="mcui-btn minimalist"
                onclick=${() => input.emit(eventToEmit)}
              >
                ${filterButtonTxt}
              </button>
            </header>
            <div>${el}</div>
          </div>
        `;
      }

      const dismissAnnouncement = (a: Announcement) =>
        input.emit(homeEvents.announcements.dismiss, a);

      return wrapWithHeader(
        input
          .getState()
          .announcements.list.map(announcementsView(dismissAnnouncement))
          .eval(
            (x) => x,
            bouncyLoader,
            () => defaultErrorMsg("Failed to load announcements")
          )
      );
    },
  },
  store: function (input) {
    const {
      removeDismissal,
      dismissAnnouncement,
      getMyAnnouncements,
      getTrouble,
    } = announcementFunctions(researchGoFetch);
    input.emitter.on(homeEvents.announcements.load, async () => {
      try {
        const announcements = await getMyAnnouncements(
          input.getState().announcements.filters
        );
        input.emitter.emit(homeEvents.announcements.loaded, announcements);
      } catch (e) {
        input.emitter.emit(homeEvents.announcements.loadError);
      }
    });

    input.emitter.on("DOMContentLoaded", () => {
      input.emitter.emit(homeEvents.announcements.load);
      input.emitter.emit(homeEvents.trouble.load);
    });

    input.emitter.on(homeEvents.announcements.loaded, (announcements) => {
      const state = input.getState();
      input.setState({
        ...state,
        announcements: {
          ...state.announcements,
          list: Complete(announcements),
        },
      });
    });
    input.emitter.on(homeEvents.announcements.loadError, (announcements) => {
      const state = input.getState();
      input.setState({
        ...state,
        announcements: {
          ...state.announcements,
          list: Err(new Error("Failed to load announcements")),
        },
      });
    });

    const filtersLens = lens(
      (state: HomeState) => state.announcements.filters,
      (filters: AnnouncementFilter, state: HomeState): HomeState => ({
        ...state,
        announcements: {
          ...state.announcements,
          filters,
        },
      })
    );
    input.emitter.on(homeEvents.announcements.showAll, () => {
      input.setState(rSet(filtersLens, "all", input.getState()));
      input.emitter.emit(homeEvents.announcements.load);
    });
    input.emitter.on(homeEvents.announcements.showUnread, () => {
      input.setState(rSet(filtersLens, "unread", input.getState()));
      input.emitter.emit(homeEvents.announcements.load);
    });

    input.emitter.on(homeEvents.announcements.dismiss, async (announcement) => {
      try {
        await dismissAnnouncement(announcement);
        input.emitter.emit(homeEvents.announcements.dismissed, announcement);
      } catch (e) {
        input.emitter.emit(
          homeEvents.announcements.dismissFailed,
          announcement
        );
      }
    });

    input.emitter.on(
      homeEvents.announcements.removeDismissal,
      async (announcement) => {
        try {
          await removeDismissal(announcement);
          input.emitter.emit(homeEvents.announcements.load);
        } catch (e) {}
      }
    );

    input.emitter.on(
      homeEvents.announcements.dismissed,
      (announcement: Announcement) => {
        input.emitter.emit(homeEvents.announcements.load);
        const u: UndoOption = {
          description: "Announcement dismissed",
          thunk: () =>
            input.emitter.emit(
              homeEvents.announcements.removeDismissal,
              announcement
            ),
        };
        input.emitter.emit(sharedEvents.undo.pushUndo, u);
      }
    );

    input.emitter.on(homeEvents.trouble.load, async () => {
      //need try catch here and fire the correct things...
      try {
        const res = await getTrouble();
        input.emitter.emit(homeEvents.trouble.loaded, res);
      } catch (e) {
        input.emitter.emit(homeEvents.trouble.loadFailed, e);
      }
    });
    input.emitter.on(homeEvents.trouble.loaded, (things) => {
      input.setState({
        ...input.getState(),
        trouble: things,
      });
      input.emitter.emit(homeEvents.trouble.waitForVisibility, {});
    });
    input.emitter.on(homeEvents.trouble.waitForVisibility, () => {
      function listeningIntently() {
        if (!document.hidden) {
          document.removeEventListener(
            "visibilitychange",
            listeningIntently,
            false
          );
          input.emitter.emit(homeEvents.trouble.load, {});
        }
      }
      document.addEventListener("visibilitychange", listeningIntently, false);
    });
  },
  initialState: {
    apps: [
      {
        displayName: "IRB",
        url: appUrls.irb,
        icon: clipboard(),
        description: "Submit Mods, CRs, and New Applications",
        external: true,
      },
      isAdmin
        ? {
            displayName: "COI",
            url: appUrls.coi,
            icon: briefCase(),
            description: "Look at Your Outstanding Financial Disclosures",
            external: true,
          }
        : null,
      isAIReviewer
        ? {
            displayName: "AI Protocol Reviews",
            url: appUrls.aiWorkspace,
            icon: thumbsUp(),
            description: "Review AI results",
            external: true,
          }
        : null,
      {
        displayName: "Research Service Center",
        url: appUrls.researchServiceCenter,
        icon: readMe(),
        description:
          "Research related question? Start here, we will respond in 4 hours or less!",
      },
      {
        displayName: "Research Protocol Catalog: Clinical Trials at Mayo",
        url: appUrls.findTrial,
        icon: documents(),
        description:
          "Find Clinical Trials, Study Team Members, and Protocol Related Documents",
        external: true,
      },
      {
        displayName: "Feedback",
        url: "/research-go/feedback",
        icon: feedback(),
        description: "We'd love to hear your thoughts on the Research Go app!",
      },
      isAdmin
        ? {
            displayName: "Administration",
            url: "admin",
            icon: gear(),
            description: "Manage the Research Go app suite",
          }
        : null,
    ].filter(Boolean),
    announcements: {
      list: Waiting,
      filters: "unread",
    },
    trouble: {},
  },
});

function toAppLi(app: App, msg: { message: string }) {
  let newWindow = "";
  if (app.displayName == "Researcher Support Knowledge Base") {
    newWindow = "_blank";
  }

  const anchor = html`
    <div>
      ${renderTroubleMessage(app, msg)}
      <a href=${app.url} target=${newWindow}>
        <div class="icon">${app.icon}</div>
        <h4>${app.displayName}</h4>
        <div class="description">${app.description}</div>
      </a>
    </div>
  `;
  if (app.external) {
    anchor.setAttribute("data-nanohref-ignore", "true");
  }
  return html` <li class=${app.disabled ? "disabled" : ""}>${anchor}</li> `;
}

const renderTroubleMessage = (app: App, msg: { message: string }) =>
  msg
    ? html` <div class="msg-trouble">${app.displayName}: ${msg.message}</div> `
    : "";
