import { compose } from "ramda";
import {
  FeedbackPayload,
  FeedbackItem,
  FeedbackNoteCreate,
  FeedbackNote
} from "./model";
import {
  withJsonBody,
  usePostMethod,
  usePatchMethod
} from "research-go-shared/lib/util/fetch-helpers";

export interface FeedbackService {
  CreateFeedback: (payload: FeedbackPayload) => void;
  GetFeedbackList: () => Promise<FeedbackItem[]>;
  getAllFeedback: () => Promise<FeedbackItem[]>;
  getNotes: (id: number) => Promise<FeedbackNote[]>;
  addNote: (note: FeedbackNoteCreate) => Promise<any>;
  markAsAddressed: (body: any) => Promise<unknown>;
}

// POST to companion API /api/feedback with FeedbackPayload
//   Returns 201 CREATED, no body
export const feedbackService: (f: any) => FeedbackService = f => ({
  CreateFeedback: async (payload: FeedbackPayload) => {
    const options = compose(
      withJsonBody(payload),
      usePostMethod
    )({});
    const res = await f("/api/feedback", options);
    if (!res.ok) {
      throw new Error("Failed to post to feedback");
    }
  },
  GetFeedbackList: async () => {
    const result = await f(`/api/feedback`);
    if (!result.ok) {
      throw new Error("We couldn't get feedback, sorry.");
    }
    return result.json();
  },
  getAllFeedback: async () => {
    const result = await f(`/api/feedback?filter=all`);
    if (!result.ok) {
      throw new Error("We couldn't get feedback, sorry.");
    }
    return result.json();
  },
  getNotes: async id => {
    const idEncoded = encodeURIComponent(id);
    const result = await f(`/api/feedbacknote?feedbackId=${idEncoded}`);
    if (!result.ok) {
      throw new Error("Unable to load notes for feedback " + id);
    }
    return result.json();
  },
  addNote: async content => {
    const options = compose(
      withJsonBody(content),
      usePostMethod
    )({});
    const result = await f("/api/feedbacknote", options);
    if (!result.ok) {
      throw new Error("Unable to add note to feedback " + content.feedbackId);
    }
  },
  markAsAddressed: async body => {
    const options = compose(
      withJsonBody(body),
      usePatchMethod
    )({});
    const result = await f(`/api/feedback/${body.feedbackId}`, options);
    if (!result.ok) {
      throw new Error("Unable to mark " + body.feedbackId + " as addressed");
    }
  }
});
