import html from "choo/html";
import { reduce, concat } from "ramda";
import { FeedbackForm } from "./provide-feedback-form";
import { FeedbackState, FeedbackPayload } from "../model";
import events from "../events";

import { ViewState } from "research-go-shared/lib/choo-modules/module";
import { View } from "research-go-shared/lib/choo-modules/view";
import { Futurish } from "useful-webapp-monads/dist/futurish";
import { defaultErrorMsg } from "research-go-shared/lib/components/error-msg";
import { defaultSuccessMsg } from "research-go-shared/lib/components/success-msg";
import { defaultInfoMsg } from "research-go-shared/lib/components/info-msg";

const email = View.of(html`
  <span>, send an email to</span>
  <a href="mailto:ResearchServiceCenter@mayo.edu">
    ResearchServiceCenter@mayo.edu</a
  >
`);

const snTicket = View.of(html`submit a</span
>
<a
href="https://mcsm.service-now.com/nav_to.do?uri=%2Fcom.glideapp.servicecatalog_cat_item_view.do%3Fv%3D1%26sysparm_id%3D7f71bbfddbedcf084f9f3cae7c96194c%26sysparm_link_parent%3D261c9c006f010e005797536e5d3ee489%26sysparm_catalog%3De0d08b13c3330100c8b837659bba8fb4%26sysparm_catalog_view%3Dcatalog_default"
target="_blank"
>
ServiceNow ticket</a
>
`);

const phoneContact = View.of(html`
  <span>, or call </span> <a href="tel:507-266-4000">507-266-4000</a>.
`);

const contactOptions = reduce(concat, View.empty, [
  snTicket,
  email,
  phoneContact
]).fold({});

export const provideFeedbackView = () => {
  return (input: ViewState<FeedbackState>) => {
    const statusPending = input.getState().statusOfSendingFeedback;
    const onSubmitForm = data => input.emit(events.feedbackSubmitted, data);
    return buildFeedbackForm(statusPending, onSubmitForm);
  };
};

const localForm = new FeedbackForm();

const theMagicForm = View(
  ({
    onSubmit,
    status
  }: {
    onSubmit: (p: FeedbackPayload) => void;
    status: Futurish<FeedbackPayload, Error>;
  }) =>
    localForm.render({
      onSubmit: payload => onSubmit(payload),
      feedbackInfo: status
    })
);

const statusElement = View((status: Futurish<FeedbackPayload, Error>) =>
  status
    ? status.eval(
        () => defaultSuccessMsg("Thanks! Your feedback has been recorded."),
        () => defaultInfoMsg("Sending feedback..."),
        () => defaultErrorMsg("There was a problem sending your feedback")
      )
    : html``
);

const infoBlock = View.of(
  defaultInfoMsg(
    html`
<div>
<span
>Need immediate assistance? Please contact the Research Service
Center (RSC); ${contactOptions}</div>
`
  )
).map((el: HTMLElement) => {
  el.classList.add("contact");
  return el;
});

const mainFormView = theMagicForm
  .concat(statusElement.contramap(x => x.status))
  .concat(infoBlock);

export function buildFeedbackForm(
  statusPending: Futurish<FeedbackPayload, Error>,
  onSubmitForm
) {
  const mainForm = mainFormView.fold({
    status: statusPending,
    onSubmit: onSubmitForm
  });

  return html`
    <div class="provide-feedback">
      <header>
        <h1>Feedback</h1>
      </header>
      <section>
<div>If you would like to share any comments or experiences about the <strong>Research Go</strong> app, please enter them below.
        ${mainForm}
      </section>
    </div>
  `;
}

const feedbackHeader = View.of(
  html`
    <header><h1>Feedback</h1></header>
  `
);
const feedback = body =>
  View.of(
    html`
      <div class="provide-feedback">${body}</div>
    `
  );
