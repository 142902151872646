export async function wireUpServiceWorkerCache(emit, on) {
  if ("serviceWorker" in navigator) {
    let refreshing = false;
    navigator.serviceWorker.addEventListener("controllerchange", function() {
      console.debug("New service worker installed! Refreshing");
      if (refreshing) {
        return;
      }
      window.location.reload();
      refreshing = true;
    });
    console.debug("REGISTERING SERVICE WORKER");
    const reg = await navigator.serviceWorker.register("../sw.js", {
      scope: "/"
    });

    console.debug("SERVICE WORKER INITIALIZED", reg.installing, reg.waiting);

    const installNewSW = async worker => {
      emit("update-required");

      on("update-approved", () => {
        worker.postMessage({ action: "skipWaiting" });
      });
    };

    if (reg.waiting) {
      console.debug("WAITING FOUND", reg.waiting);
      installNewSW(reg.waiting);
    } else if (reg.installing) {
      console.debug("INSTALLING NEW, will prompt refresh when done!");
      const worker = reg.installing;

      worker.addEventListener("statechange", function() {
        console.debug("State changed!");
        if (worker.state === "installed") {
          console.debug("Installed done! Skipping waiting");
          installNewSW(worker);
        }
      });
    } else {
      reg.addEventListener("updatefound", function() {
        const worker = reg.installing;
        console.debug("UPDATE FOUND FOR ", reg.installing);
        worker.addEventListener("statechange", function() {
          console.debug("State changed!");
          if (worker.state === "installed") {
            console.debug("Installed done! Skipping waiting");
            installNewSW(worker);
          }
        });
      });
    }

    return reg;
  } else {
    console.debug("Not registering");
    return Promise.resolve();
  }
}
