import { getJson_ } from "research-go-shared/lib/util/fetch-helpers";

export interface TicketServices {
  getMyTickets: () => Promise<any>;
}

const getMyTickets = httpGet => () => httpGet("/api/ticket");

export const ticketServices: (f: any) => TicketServices = f => {
  const getJson = getJson_(f);
  return {
    getMyTickets: getMyTickets(getJson)
  };
};
