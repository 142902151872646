import {
  Futurish,
  Waiting,
  Complete,
  Err
} from "useful-webapp-monads/dist/futurish";
import { ServiceTicket } from "./model";
import { Emitter } from "research-go-shared/lib/choo-modules/event-emitter";
import events from "./events";

export const ticketEventListeners = (
  setState: (s: Futurish<ServiceTicket[], Error> | null) => void,
  emitter: Emitter,
  svc: any
) => {
  emitter.on(events.loadTickets, async () => {
    setState(Waiting);
    try {
      const result = await svc.getMyTickets();
      emitter.emit(events.loadTicketsSuccess, result);
    } catch (e) {
      emitter.emit(events.loadTicketsError, e);
    }
  });
  emitter.on(events.loadTicketsSuccess, (payload: ServiceTicket[]) => {
    setState(Complete(payload));
  });
  emitter.on(events.loadTicketsError, () => {
    setState(Err(new Error("Failed to load tickets.")));
  });
  emitter.on(
    events.loadExternalLink,
    (payload: { serviceTicket: ServiceTicket; serviceNowUrl: string }) => {
      const theUrl = `${payload.serviceNowUrl}/nav_to.do?uri=task.do?sysparm_query=number=${payload.serviceTicket.ticketNumber}`;
      window.open(theUrl, "_blank");
    }
  );
};
