export const homeEvents = {
  announcements: {
    loaded: "[research-go] [home] announcements loaded",
    dismiss: "[research-go] [home] dismiss announcement",
    load: "[research-go] [home] load announcements",
    loadError: "[research-go] [home] error loading announcements",
    dismissed: "[research-go] [home] announcement dismissed",
    dismissFailed: "[research-go] [home] announcement dismiss error",
    removeDismissal: "[research-go] [home] remove announcement dismissal",
    showUnread: "[research-go] [home] show unread announcements",
    showAll: "[research-go] [home] show all announcements"
  },
  trouble: {
    load: "[research-go] [trouble] load",
    loaded: "[research-go] [trouble] loaded",
    waitForVisibility: "[research-go] [trouble] wait for visibility",
    loadFailed: "[research-go] [trouble] load failed";
  }
};
