import {
  AppModule,
  ModuleState
} from "research-go-shared/lib/choo-modules/module";
import {
  FeedbackState,
  FeedbackPayload,
  FeedbackItem,
  FeedbackNote,
  FeedbackDiscussionItem
} from "./model";
import { provideFeedbackView } from "./views/provide-feedback";
import "./feedback.scss";
import "./views/review-feedback.scss";
import {
  feedbackEventListeners,
  feedbackReviewEventHandlers,
  feedbackNotesEventHandlers
} from "./effects";
import { lensProp, lensPath } from "ramda";
import { feedbackService, FeedbackService } from "./service";
import { reviewFeedbackView } from "./views/review-feedback";
import html from "choo/html";
import { withAdapter, fetchAdapter } from "azure-user-profile";
import events from "./events";
import { narrowByLens } from "research-go-shared/lib/util/module-util";
import {
  Futurish,
  Complete,
  NotStarted
} from "useful-webapp-monads/dist/futurish";
import { bouncyLoader } from "research-go-shared/lib/components/loader";
import { defaultErrorMsg } from "research-go-shared/lib/components/error-msg";

export const feedbackModule: (
  f: any,
  graphAccessToken: () => string,
  isAdmin: () => boolean
) => AppModule<FeedbackState> = (
  notificationFetch,
  graphAccessToken,
  isAdmin
) => ({
  views: {
    "": provideFeedbackView(),
    "/review": viewState => {
      const feedbackItemsP: Futurish<
        FeedbackItem[],
        Error
      > = viewState.getState().feedbackList;
      const onClickFeedback = id =>
        viewState.emit(events.selectDiscussionFor, id);
      return feedbackItemsP.eval(
        data =>
          reviewFeedbackView.fold({
            componentCache: viewState.cache,
            showAddressed: viewState.getState().showAllFeedback,
            toggleShowAll: () => viewState.emit(events.toggleShowAll),
            selectedFeedback: viewState.getState().selectedFeedback,
            onNoteSubmit: (feedbackId, note) =>
              viewState.emit(events.addNote, { feedbackId, note }),
            feedback: data,
            viewNotes: onClickFeedback,
            resolveFeedback: id => viewState.emit(events.markAsAddressed, id),
            closeDiscussion: () => viewState.emit(events.clearSelectedFeedback),
            selectedNotes:
              viewState.getState().notes[
                viewState.getState().selectedFeedback
              ] || NotStarted
          }),

        () =>
          html`
            <div id="review-feedback">${bouncyLoader()}</div>
          `,
        () => defaultErrorMsg("Failed to load feedback items")
      );
    }
  },
  store: (input: ModuleState<FeedbackState>) => {
    feedbackEventListeners(
      narrowByLens<FeedbackState, Futurish<FeedbackPayload, Error> | null>(
        input.getState,
        input.setState,
        lensProp("statusOfSendingFeedback")
      ).setState,
      input.emitter,
      feedbackService(notificationFetch)
    );

    const { getUser, getMemberPhoto } = withAdapter(
      fetchAdapter(graphAccessToken())
    );

    const setNote = (
      feedbackId: string,
      ns: Futurish<FeedbackDiscussionItem[], Error>
    ) => {
      const setNoteState = narrowByLens<
        FeedbackState,
        Futurish<FeedbackDiscussionItem[], Error>
      >(input.getState, input.setState, lensPath(["notes", feedbackId]))
        .setState;
      setNoteState(ns);
    };
    const setSelectedFeedback = (x: number) =>
      input.setState({ ...input.getState(), selectedFeedback: x });

    if (isAdmin()) {
      const svc: FeedbackService = feedbackService(notificationFetch);
      const setFeedbackItems = narrowByLens<
        FeedbackState,
        Futurish<FeedbackItem[], Error>
      >(input.getState, input.setState, lensProp("feedbackList")).setState;
      feedbackReviewEventHandlers(
        setFeedbackItems,
        svc,
        getUser,
        getMemberPhoto,
        input.emitter
      );
      feedbackNotesEventHandlers(
        input.emitter,
        setNote,
        svc,
        setSelectedFeedback,
        getUser,
        getMemberPhoto
      );
    }

    input.emitter.on(events.toggleShowAll, () => {
      const state = input.getState();
      input.setState({
        ...state,
        showAllFeedback: !state.showAllFeedback
      });
      input.emitter.emit(events.loadFeedbackList, !state.showAllFeedback);
    });
  },
  initialState: {
    statusOfSendingFeedback: null,
    feedbackList: Complete([]),
    notes: {},
    selectedFeedback: null,
    showAllFeedback: false
  }
});
