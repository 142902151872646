import {
  AppModule,
  ViewState,
  ModuleState
} from "research-go-shared/lib/choo-modules/module";
import "./ticket.scss";
import {
  Futurish,
  Complete,
  NotStarted
} from "useful-webapp-monads/dist/futurish";
import { TicketState, ServiceTicket } from "./model";
import { provideTicketView } from "./ticket-view";
import { ticketEventListeners } from "./effects";
import { narrowByLens } from "research-go-shared/lib/util/module-util";
import { lensProp } from "ramda";
import { ticketServices } from "./service";

export const ticketModule = (
  f: any,
  serviceNowUrl: string
): AppModule<TicketState> => {
  return {
    initialState: { ticketList: NotStarted },
    store: (input: ModuleState<TicketState>) => {
      ticketEventListeners(
        narrowByLens<TicketState, Futurish<ServiceTicket[], Error> | null>(
          input.getState,
          input.setState,
          lensProp("ticketList")
        ).setState,
        input.emitter,
        ticketServices(f)
      );
    },
    views: {
      "": provideTicketView(serviceNowUrl)
    }
  };
};
