import html from "nanohtml";
import Component from "choo/component";
import { lensPath, pathOr, toPairs, fromPairs } from "ramda";
import {
  AppModule,
  ViewState,
  ModuleState
} from "research-go-shared/lib/choo-modules/module";

import { close } from "research-go-shared/src/icons";

import { serviceRequestFns } from "research-go-shared/lib/servicerequest/service";

import {
  Futurish,
  NotStarted,
  Waiting,
  Complete,
  Err
} from "useful-webapp-monads/dist/futurish";
import { ViewT, View } from "research-go-shared/lib/choo-modules/view";
import { defaultErrorMsg } from "research-go-shared/lib/components/error-msg";
import { bouncyLoader } from "research-go-shared/lib/components/loader";
import "./service.scss";
import { narrowByLens } from "research-go-shared/lib/util/module-util";
import { defaultInfoMsg } from "research-go-shared/lib/components/info-msg";
import events from "../ticket/events";

interface FormsHash {
  [key: string]: string;
}

interface FormDefinitionHash {
  [key: string]: Futurish<any, Error> | undefined;
}

interface ServiceState {
  forms: Futurish<FormsHash, Error>;
  formDefinitions: FormDefinitionHash;
  formSubmissions: { [key: string]: Futurish<string, Error> };
}

const serviceEvents = {
  loadFormDefinition: "[research go] [service center] load form definition",
  submitServiceForm: "[research go] [service center] submit service form",
  clearFormSubmission: "[research go] [service center] clear form submission"
};

const formList = (forms: FormsHash) => {
  const formLink = ([id, name]) =>
    html`
      <li><a href="/research-go/service/${id}">${name}</a></li>
    `;
  return html`
    <ul>
      ${toPairs(forms).map(formLink)}
    </ul>
  `;
};

const serviceListWrapper: ViewT<ServiceState> = View(
  (serviceState: ServiceState) =>
    html`
      <div id="service-form-list">
        <div>What kind of request would you like to make?</div>
        ${serviceState.forms.eval(formList, bouncyLoader, () =>
          defaultErrorMsg("Failed to load service forms")
        )}
      </div>
    `
);

const serviceCenterWrapper = (body: HTMLElement | string) =>
  View(
    () => html`
      <header><h1>Service Center</h1></header>
      <div>
        ${body}
      </div>
    `
  );

const wrapWithBoilerplate = (view: ViewT<ServiceState>) => (
  input: ViewState<ServiceState>
) => {
  const el = view.chain(serviceCenterWrapper).fold(input.getState());
  el.setAttribute("id", "research-go-service");
  return el;
};

const labelWrap = bodyRender => def =>
  html`
    <li><label>${def.label}${bodyRender(def)}</label></li>
  `;

const buildSelectBox = multiple => def => {
  //console.log(def);
  const toOption = c =>
    html`
      <option value=${c.value}>${c.label}</option>
    `;
  return html`
    <select data-sn-name=${def.name} multiple=${multiple}
      >${def.choices.map(toOption)}</select
    >
  `;
};

const buildInput = def =>
  html`
    <input data-sn-name=${def.name} type="text" />
  `;

const buildTextArea = def =>
  html`
    <textarea data-sn-name=${def.name}></textarea>
  `;

const renderers = {
  multiple_choice: labelWrap(buildSelectBox(true)),
  select_box: labelWrap(buildSelectBox(false)),
  single_line_text: labelWrap(buildInput),
  multi_line_text: labelWrap(buildTextArea)
};

const renderFormElement = def => {
  const renderer =
    renderers[def.friendly_type] ||
    (() =>
      html`
        <div></div>
      `);
  //console.log("rendering ", def.label, def.friendly_type);
  return html`
    ${renderer(def)} ${(def.children || []).map(renderFormElement)}
  `;
};

function getInputValue(x: HTMLInputElement) {
  return x.type === "checkbox" ? x.checked : x.value;
}

function getValueFor(
  x: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
) {
  if (x.tagName.toLowerCase() === "input") {
    return getInputValue(x);
  } else if (x.tagName.toLowerCase() === "textarea") {
    return x.value;
  } else {
    if (x.getAttribute("multiple")) {
      return [].slice
        .call(x.querySelectorAll("option:checked"))
        .map(x => x.value);
    } else {
      return (x.querySelector("option:checked") as HTMLOptionElement).value;
    }
  }
}

class LocalForm extends Component {
  private submitForm: (formData: any) => void;
  private lastKnownStatus: Futurish<string, Error> = NotStarted;
  onSubmit(e) {
    e.preventDefault();
    console.log("SUBMITTING ", e);
    const formToSubmit = [].slice
      .call(e.target.querySelectorAll("input, select, textarea"))
      .map(x => [x.getAttribute("data-sn-name"), getValueFor(x)]);
    this.submitForm(fromPairs(formToSubmit));
    return false;
  }

  update(data) {
    if (data.getFormStatus(data.formId) !== this.lastKnownStatus) {
      //only rerender (clear form) if current status is complete
      return data.getFormStatus(data.formId).eval(
        () => true,
        () => false,
        () => false,
        () => false
      );
    }
    return false;
  }

  createElement(data) {
    this.submitForm = data.submitForm;
    this.lastKnownStatus = data.getFormStatus(data.formId);

    const howToClear = () => data.clearFormStatus(data.formId);

    return html`
      <div>
        <form id="submit-to-sn" onsubmit=${this.onSubmit.bind(this)}>
          <ul>
            ${data.definitions.map(renderFormElement)}
          </ul>
          <button class="mcui-btn primary" type="submit">Submit</button>
        </form>
      </div>
    `;
  }
}

const renderTicketNumber = howToClear => ticketNumber =>
  defaultInfoMsg(html`
    Your service request has been submitted. ${ticketNumber}
    <button onclick=${howToClear}>${close()}</button>
  `);

const renderGenericForm = submitForm => cache => getFormStatus => clearFormStatus => def => {
  return cache(LocalForm, def.result["sys_id"]).render({
    definitions: def.result.variables,
    submitForm,
    getFormStatus,
    clearFormStatus,
    formId: def.result["sys_id"]
  });
};

const singleForm = (input: ViewState<ServiceState>) =>
  View((x: ServiceState) => {
    const formId = input.routeParams().path.id;
    const getFormDefinition: (id: string) => Futurish<any, Error> = id =>
      pathOr(NotStarted, ["formDefinitions", id], input.getState());
    const getFormName = (id: string) =>
      input.getState().forms.eval(
        x => x[id],
        () => "",
        () => "",
        () => ""
      );
    getFormDefinition(formId).eval(
      id => {},
      () => {},
      () => {},
      () => input.emit(serviceEvents.loadFormDefinition, formId)
    );
    const getStatus = id => input.getState().formSubmissions[id] || NotStarted;
    const howToClear = () => {
      input.emit(serviceEvents.clearFormSubmission, formId);
    };
    return html`
      <h4>
        ${getFormName(formId)}
      </h4>
      ${getFormDefinition(formId).eval(
        renderGenericForm(form =>
          input.emit(serviceEvents.submitServiceForm, {
            formData: form,
            formId
          })
        )(input.cache)(getStatus)(id =>
          input.emit(serviceEvents.clearFormSubmission, id)
        ),
        bouncyLoader,
        () => defaultErrorMsg("Failed to load form"),
        () => ""
      )}
      ${getStatus(formId).eval(
        renderTicketNumber(howToClear),
        () => defaultInfoMsg("Submitting your service request"),
        () =>
          defaultErrorMsg(html`
            Failed to submit your service request.

            <button onclick=${howToClear}>${close()}</button>
          `),
        () => html``
      )}
    `;
  });

const autoRedirectView = (id: string) =>
  function(input: ViewState<ServiceState>) {
    setTimeout(() => input.emit("pushState", `/research-go/service/${id}`));
    return html``;
  };

export const serviceModule: (
  f: any
) => Promise<AppModule<ServiceState>> = async rgFetch => {
  const {
    getKnownForms,
    getSingleForm,
    createServiceRequest
  } = serviceRequestFns(rgFetch);

  try {
    const res = await getKnownForms();

    return {
      views: {
        "":
          Object.keys(res).length === 1
            ? autoRedirectView(Object.keys(res)[0])
            : wrapWithBoilerplate(serviceListWrapper),
        "/:id": input => wrapWithBoilerplate(singleForm(input))(input)
      },
      store: function(input: ModuleState<ServiceState>) {
        input.emitter.on("DOMContentLoaded", async () => {
          input.setState({
            ...input.getState(),
            forms: Waiting
          });

          input.setState({
            ...input.getState(),
            forms: Complete(res)
          });
        });

        input.emitter.on(serviceEvents.loadFormDefinition, async id => {
          const setForm = narrowByLens<ServiceState, Futurish<any, Error>>(
            input.getState,
            input.setState,
            lensPath(["formDefinitions", id])
          ).setState;

          setForm(Waiting);

          try {
            const res = await getSingleForm(id);
            setForm(Complete(res));
          } catch (e) {
            setForm(Err(e));
          }
        });

        input.emitter.on(serviceEvents.submitServiceForm, async formInfo => {
          try {
            input.setState({
              ...input.getState(),
              formSubmissions: {
                ...input.getState().formSubmissions,
                [formInfo.formId]: Waiting
              }
            });

            const res = await createServiceRequest(
              formInfo.formId,
              formInfo.formData
            );
            console.log("Submitted ", res);

            const ticketNumber = pathOr("", ["result", "number"], res);

            input.setState({
              ...input.getState(),
              formSubmissions: {
                ...input.getState().formSubmissions,
                [formInfo.formId]: Complete(ticketNumber)
              }
            });

            input.emitter.emit(events.loadTickets);
          } catch (e) {
            input.setState({
              ...input.getState(),
              formSubmissions: {
                ...input.getState().formSubmissions,
                [formInfo.formId]: Err(e)
              }
            });
          }
        });

        input.emitter.on(serviceEvents.clearFormSubmission, id => {
          input.setState({
            ...input.getState(),
            formSubmissions: {
              ...input.getState().formSubmissions,
              [id]: NotStarted
            }
          });
        });
      },
      initialState: {
        forms: NotStarted,
        formDefinitions: {},
        formSubmissions: {}
      }
    };
  } catch (e) {
    return {
      views: {},
      initialState: {
        forms: Err(new Error(e)),
        formDefinitions: {},
        formSubmissions: {}
      },
      store: function(input) {}
    };
  }
};
