export default {
  feedbackSubmitted: "[irb] feedback submitted",
  feedbackSuccess: "[irb] feedback success",
  feedbackError: "[irb] feedback error",
  markAsAddressed: "[irb] feedback -- mark as addressed",
  selectDiscussionFor: "[irb] feedback -- load discussion",
  loadNotesFor: "[irb] feedback -- load notes",
  notesLoadedFor: "[irb] feedback -- notes loaded",
  notesLoadError: "[irb] feedback -- notes load error",
  addNote: "[irb] feedback -- add note to discussion",
  clearSelectedFeedback: "[irb] feedback selection cleared",
  loadFeedbackList: "[irb] feedback -- load list",
  toggleShowAll: "[irb] feedback -- toggle show all"
};
